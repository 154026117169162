body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
   position: relative;
   min-height: 100vh;
   height: 100vh;
}

.nav-pills > .nav-link,
.nav-pills > .nav-link:hover,
.nav-pills > li > a{
   color: #6d6d6d !important;
   background-color: transparent !important;
   text-decoration: none;
  font-weight: bold;
  border-radius:0px;
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
}

.nav-pills > .nav-link.active,
.nav-pills > .nav-link.active:hover,
.nav-pills > li > a:hover {
   color: #000 !important;
   border-bottom-color: #343a40;
}
.nav-pills > li > a{
   border-radius:0px;
}

/** NEW DESIGN */
.background-grad-ver{
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#082f9b+0,0163ee+100 */
   background: #082f9b; /* Old browsers */
   background: -moz-linear-gradient(top, #082f9b 0%, #0163ee 100%); /* FF3.6-15 */
   background: -webkit-linear-gradient(top, #082f9b 0%,#0163ee 100%); /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to bottom, #082f9b 0%,#0163ee 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#082f9b', endColorstr='#0163ee',GradientType=0 ); /* IE6-9 */
}

.background-grad-hor{
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#082f9b+0,0163ee+100 */
   background: #082f9b; /* Old browsers */
   background: -moz-linear-gradient(left, #082f9b 0%, #0163ee 100%); /* FF3.6-15 */
   background: -webkit-linear-gradient(left, #082f9b 0%,#0163ee 100%); /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to right, #082f9b 0%,#0163ee 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#082f9b', endColorstr='#0163ee',GradientType=1 ); /* IE6-9 */
}
.background-grad-hor-reverce{
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0163ee+0,082f9b+100 */
   background: #0163ee; /* Old browsers */
   background: -moz-linear-gradient(left, #0163ee 0%, #082f9b 100%); /* FF3.6-15 */
   background: -webkit-linear-gradient(left, #0163ee 0%,#082f9b 100%); /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to right, #0163ee 0%,#082f9b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0163ee', endColorstr='#082f9b',GradientType=1 ); /* IE6-9 */
}

.text-custom-blue{
   color: #073aad;
}

.text-custom-green{
   color: #0ad000;
}

.button-rounded-1 {border-radius: 25px;}

.bg-white{
   background-color: white !important;
}

.bg-transparent{
   background-color: transparent !important;
}

.container-signup{
   border: 1px solid #FFFFFF !important;
   border-radius: 20px 20px 0 0 !important;
   background-color: white !important;
}

.container-login .form-control,
.container-signup .form-control{
   border-left: transparent;
   border-right: transparent;
   border-top: transparent;
}

.share-btn{
   
}
